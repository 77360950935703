// Video module

import './vendors/_vendor.js';

class BackgroundVideo {
    constructor() {
        let videoContainers = document.querySelectorAll('.video-local, .video-youtube');

        for (const container of videoContainers) {
            this.initVideo(container);
        }
    }

    initVideo(container) {
        if (container.dataset.type == 'local') {
            container.addEventListener(
                "playing",
                () => {
                    container.classList.add("is-playing");
                },
                true
            );

            return;
        } else if (container.dataset.type == 'youtube') {
            var youtube_id = container.getAttribute("data-youtube-id");

            if (!youtube_id) {
                return;
            }

            container.addEventListener(
                "playing",
                function () {
                    container.classList.add("is-playing");
                },
                true
            );

            var streams = '';

            fetch(
                "https://images" +
                ~~(Math.random() * 33) +
                "-focus-opensocial.googleusercontent.com/gadgets/proxy?container=none&url=https%3A%2F%2Fwww.youtube.com%2Fget_video_info%3Fvideo_id%3D" +
                youtube_id
            )
                .then((response) => response.text())
                .then((data) => {
                    if (data) {
                        console.log(youtube_id);
                        console.log(data);
                        console.log("data is fetched");

                        streams = this.parse_youtube_meta(data);

                        console.log(streams);
                        container.src =
                            streams["1080p"] ||
                            streams["720p"] ||
                            streams["360p"];
                    } else {
                        console.log("Youtube API Error");
                    }
                });
        }
    }

    parse_youtube_meta(rawdata) {
        var data = this.parse_str(rawdata),
            player_response = JSON.parse(data.player_response),
            streams = [],
            result = {};

        data.player_response = player_response;

        if (data.hasOwnProperty("adaptive_fmts")) {
            streams = streams.concat(
                data.adaptive_fmts.split(",").map(function (s) {
                    return this.parse_str(s);
                })
            );
        } else {
            if (
                player_response.streamingData &&
                player_response.streamingData.adaptiveFormats
            ) {
                streams = streams.concat(
                    player_response.streamingData.adaptiveFormats
                );
            }
        }
        if (data.hasOwnProperty("url_encoded_fmt_stream_map")) {
            streams = streams.concat(
                data.url_encoded_fmt_stream_map.split(",").map(function (s) {
                    return this.parse_str(s);
                })
            );
        } else {
            if (
                player_response.streamingData &&
                player_response.streamingData.formats
            ) {
                streams = streams.concat(player_response.streamingData.formats);
            }
        }

        streams.forEach((stream, n) => {
            var itag = stream.itag * 1,
                quality = false,
                itag_map = {
                    18: "360p",
                    22: "720p",
                    37: "1080p",
                    38: "3072p",
                    82: "360p3d",
                    83: "480p3d",
                    84: "720p3d",
                    85: "1080p3d",
                    133: "240pna",
                    134: "360pna",
                    135: "480pna",
                    136: "720pna",
                    137: "1080pna",
                    264: "1440pna",
                    298: "720p60",
                    299: "1080p60na",
                    160: "144pna",
                    139: "48kbps",
                    140: "128kbps",
                    141: "256kbps",
                };
            //if (stream.type.indexOf('o/mp4') > 0) console.log(stream);
            if (itag_map[itag]) result[itag_map[itag]] = stream.url;
        });
        return result;
    }

    parse_str(str) {
        return str.split("&").reduce(function (params, param) {
            var paramSplit = param.split("=").map(function (value) {
                return decodeURIComponent(value.replace("+", " "));
            });
            params[paramSplit[0]] = paramSplit[1];
            return params;
        }, {});
    }
}

new BackgroundVideo();

// If using jquery 1.9.1 or below import the components inside the ( function($) )
(function ($) {
    $(document).ready(function () {

        $('.kundcase-slick').each(function() {
            var slidesToShow = $(this).data('slidesperrow') ?? 4;

            $(this).slick({
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: slidesToShow,
                centerMode: false,
                adaptiveHeight: true,
                responsive: [
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                      }
                    }
                ]
             });
        });

        $('.kundcase-gallery').each(function() {
            var $images = $(this).find('.images img');
            var $toggleBtn = $(this).find('.toggleGallery');

            const imagesPerRow = $(this).data('imagesperrow') ?? 4;

            if(!$images.length)
                return false;

            if($images.length > imagesPerRow) {
                var $topRow = $images.splice(0, imagesPerRow);

                $images.each(function() {
                    $(this).hide();
                });

                $toggleBtn.css('display','block');

                $toggleBtn.on('click', function(e) {
                    e.preventDefault();
                    $images.fadeIn();

                    $(this).hide();
                });
            }
        });

        $(".top-header .hamburger-desktop").click(function () {
            var width = $(window).width();

            $(this).toggleClass('open');

            if (width >= 991) {
                $('.main-navigation').slideToggle();
            } else {
                //$('.main-navigation').show().toggleClass('active');
                $(".switchers_holder").toggleClass("active");
                $(".main-navigation").toggleClass("active");
                $(".site-header .border_bottom").toggleClass("active");

                if ($(this).hasClass("open")) {
                    $(".switchers_holder").removeClass("hide");
                    $(".produkt_cats").removeClass("active");
                    $(".button-container").removeClass("active");
                    $(".language_switcher").removeClass("opened");
                    $(".languages").removeClass("opened");
                    $(".search_button").removeClass("opened");
                    $(".search-content").removeClass("opened");
                    $(".search-content input").removeClass("opened");
                }
            }
        });
    });
})(jQuery);
